import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class CareerJob {
  @JsonProperty() name?: string

  @JsonProperty() tel?: string

  @JsonProperty() email?: string

  @JsonProperty() lang?: string = "TH"

  @JsonProperty({ type: File }) file?: File
}
